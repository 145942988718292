import axios, { AxiosError } from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getMainDataAction } from '../mainData/mainDataSlice'
import { setNode } from '../selectedNode/slice'
import {
    deleteIdAction,
    deleteIdActionState,
    IEditFormState,
    reSetcreateOrEditIdState,
    setcreateOrEditId,
    setcreateOrEditIdError,
    setcreateOrEditIdState,
    submitCreateOrEditId,
    submitEditId,
} from './createOrEditId'

export const createId = async (state: any): Promise<any> => {
    const res = await axios.post(`/api/ids/id/${state?.id?.input}`, {
        IdInput: state?.id?.input,
        Kennisgebied: state?.kennisgebied?.input,
        Categorie: state?.categories?.input,
        Onderdeel: state?.onderdeels?.input,
        ID_verwijzing: state?.verwijzing?.input,
        ID_status: state?.id_status?.input,
        title: state?.title?.input,
        KG_Cat_Onderdeel: `${state?.kennisgebied?.input}_${state?.categories?.input}_${state?.onderdeels?.input}`,
        Trefwoorden: state?.trefwoorden?.input,
        ID_contactpersoon: state?.contactpersoon?.input,
    })
    return res
}

export const updateId = async (state: any): Promise<any> => {
    const res = await axios.patch(`/api/ids/id/${state?.dbId}`, {
        IdInput: state?.id?.input,
        Kennisgebied: state?.kennisgebied?.input,
        Categorie: state?.categories?.input,
        Onderdeel: state?.onderdeels?.input,
        ID_verwijzing: state?.verwijzing?.input,
        ID_status: state?.id_status?.input,
        title: state?.title?.input || 'Applicatie',
        KG_Cat_Onderdeel: `${state?.kennisgebied?.input}_${state?.categories?.input}_${state?.onderdeels?.input}`,
        Trefwoorden: state?.trefwoorden?.input,
        ID_contactpersoon: state?.contactpersoon?.input,
    })
    return res
}

export const deleteId = async (payload: any): Promise<any> => {
    const res = await axios.delete(`/api/ids/${payload?.id}`)
    return res
}

function* createSaga(action: any): any {
    const prevState = action.payload

    // const isUpdate = action.payload.isUpdate

    const { dbId, isUpdate, refreshData, ...desiredState } = prevState
    const newStateArray = yield Object.entries(
        desiredState as IEditFormState
    ).map(([key, value]) => {
        if (!value.input || value?.input?.length === 0) {
            return [key, { ...value, error: 'please add or select a value' }]
        } else return [key, value]
    })
    const state = Object.fromEntries(newStateArray)

    if (
        !state?.categories?.error &&
        !state?.id?.error &&
        !state?.kennisgebied?.error &&
        !state?.onderdeels?.error &&
        !state?.verwijzing?.error &&
        // !state?.title?.error &&
        !state?.id_status?.error
    ) {
        try {
            yield put(setcreateOrEditIdState({ loading: true }))

            yield isUpdate
                ? call(updateId, { ...state, dbId })
                : call(createId, { ...state })
            // yield put(setcreateOrEditId({ editFormState: data }))
            yield put(setNode(refreshData))
            yield put(
                setcreateOrEditIdState({ loading: false, openModal: false })
            )
            yield put(reSetcreateOrEditIdState({ type: 'RESET' }))
        } catch (error) {
            yield put(
                setcreateOrEditIdError({
                    error: (error as AxiosError).response?.data,
                })
            )
            yield put(setcreateOrEditIdState({ loading: false }))
        }
    } else yield put(setcreateOrEditId({ ...state }))
}

function* editSaga(action: any): any {
    const prevState = action.payload.editFormState

    const newStateArray = yield Object.entries(prevState as IEditFormState).map(
        ([key, value]) => {
            if (value.input.length === 0) {
                return [
                    key,
                    { ...value, error: 'please add or select a value' },
                ]
            } else return [key, value]
        }
    )

    const state = Object.fromEntries(newStateArray)

    if (
        !state?.categories?.error &&
        !state?.id?.error &&
        !state?.kennisgebied?.error &&
        !state?.onderdeels?.error &&
        !state?.verwijzing?.error &&
        !state?.id_status?.error
    ) {
        try {
            yield put(setcreateOrEditIdState({ loading: true }))
            const data = yield call(updateId, { ...state })
            yield put(setcreateOrEditId({ editFormState: data }))
            yield put(
                setcreateOrEditIdState({ loading: false, openModal: false })
            )
            yield put(reSetcreateOrEditIdState({ type: 'RESET' }))
        } catch (error) {
            yield put(
                setcreateOrEditIdError({
                    error: (error as AxiosError).response?.data,
                })
            )
            yield put(setcreateOrEditIdState({ loading: false }))
        }
    } else yield put(setcreateOrEditId({ ...state }))
}

export function* deleteIdSaga(action: any): any {
    const payload = action.payload
    try {
        yield put(setcreateOrEditIdState({ loading: true }))
        const data = yield call(deleteId, { ...payload })
        yield put(setNode(payload?.refreshData))
        yield put(getMainDataAction())
        yield put(deleteIdActionState({ loading: false }))
    } catch (error) {
        yield put(
            setcreateOrEditIdError({
                error: (error as AxiosError).response?.data,
            })
        )
        yield put(setcreateOrEditIdState({ loading: false }))
    }
}

export function* createWatcher() {
    yield takeLatest(submitCreateOrEditId, createSaga)
}

export function* deleteWatcher() {
    yield takeLatest(deleteIdAction, deleteIdSaga)
}

export function* editWatcher() {
    yield takeLatest(submitEditId, editSaga)
}
