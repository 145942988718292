import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { getMainDataAction } from '../redux/mainData/mainDataSlice'
import { useAppDispatch } from '../redux/store'

export interface ILayoutCompProps {
    children: React.ReactChild | React.ReactChild[]
}

function Layout({ children }: ILayoutCompProps) {
    return (
        <Flex w="100%" h="100%" flexDirection="column" alignItems="center">
            {children}
        </Flex>
    )
}

export default Layout
