import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface IMainState {
    ids?: any
    defaultIdStatus?: any
    kennisgebied?: any
    categories?: any
    onderdeels?: any
    idStatus?: any
    admin?: any
    loading: boolean
    error?: any
}

const initialState: IMainState = {
    ids: null,
    kennisgebied: null,
    categories: null,
    onderdeels: null,
    idStatus: null,
    loading: false,
    admin: null,
    error: null,
}

const mainSlice = createSlice({
    name: 'mainSlice',
    initialState,
    reducers: {
        // getMainNewsData() {},
        setIds: (state, action: PayloadAction<IMainState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setKennisgebied: (state, action: PayloadAction<IMainState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setCategories: (state, action: PayloadAction<IMainState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setAdmin: (state, action: PayloadAction<IMainState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setOnderdeels: (state, action: PayloadAction<IMainState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setIdStatus: (state, action: PayloadAction<IMainState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setActiveArticle: (state, action: PayloadAction<IMainState>) => {
            const active = action.payload
            return { ...state, ...active }
        },
        getMainDataAction: () => {},
    },
})

export const {
    setIds,
    setActiveArticle,
    setKennisgebied,
    setCategories,
    setOnderdeels,
    setIdStatus,
    getMainDataAction,
    setAdmin,
} = mainSlice.actions

export default mainSlice.reducer
