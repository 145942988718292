import { call, put } from 'redux-saga/effects'
import { setOnderdeels } from './mainDataSlice'

const getOnderdeelData = async (): Promise<any> => {
    const res = await fetch('/api/onderdeels', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return res.json()
}

export function* onderdeelSaga(): any {
    try {
        yield put(setOnderdeels({ loading: true }))
        const data = yield call(getOnderdeelData)

        yield put(setOnderdeels({ loading: false, onderdeels: data }))
    } catch (error) {
        yield put(
            setOnderdeels({
                error: { isError: true, value: error },
                loading: false,
            })
        )
    }
}
