import { Box } from '@chakra-ui/react'
import TreeMenu, { TreeNode } from 'react-simple-tree-menu'
import '../../src/css/main.css'
import IId from '../redux/selectedNode/id.interface'
import {
    reSetSelectNodeState,
    setBreadCrump,
    setNode,
    setSelectedNode,
} from '../redux/selectedNode/slice'
import { useAppDispatch, useAppSelector } from '../redux/store'

export interface NodeIdentefier {
    id: number
    name: 'string'
}

export interface ITreeNode {
    key: number | string
    label: string
    nodes: ITreeNode[]
}

export interface IFindMethodProps {
    label: string
    key: string
    searchTerm: string
    ond_trefwoorden?: string
    Trefwoorden?: string
    data?: any
    nodes?: any[]
}

function Treeview(props: any) {
    const { kennisgebied, onderdeels, ids } = useAppSelector(
        (state) => state.mainState
    )
    const dispatch = useAppDispatch()

    let categories = ids?.filter(
        (v: IId, i: number, a: any) =>
            a.findIndex((t: any) => t.Categorie === v.Categorie) === i
    )

    categories =
        categories &&
        categories.length > 0 &&
        [...categories]?.map((cat, i) => ({
            ...cat,
            name: cat.Categorie,
            id: i,
        }))

    let CatOnderdeel = categories?.map((item: any) => {
        let onds =
            ids &&
            ids
                .filter((el: IId) => item.name === el.Categorie)
                .filter(
                    (v: IId, i: number, a: any) =>
                        a.findIndex((t: any) => t.Onderdeel === v.Onderdeel) ===
                        i
                )

        onds = [...onds]
            .sort((a, b) => a.Onderdeel.localeCompare(b.Onderdeel))
            .map((item) => ({
                ...item,
                key: item.Onderdeel,
                label: item.Onderdeel,

                nodes: [],
            }))

        return { ...item, key: item.name, label: item.name, nodes: onds }
    })

    const kennisgebiedCats = kennisgebied
        ?.map((ken: any) => {
            let cats =
                ids &&
                ids
                    .filter(
                        (el: IId) =>
                            ken.name.toLowerCase() ===
                            el.Kennisgebied.toLowerCase()
                    )
                    .filter(
                        (v: IId, i: number, a: any) =>
                            a.findIndex(
                                (t: any) => t.Categorie === v.Categorie
                            ) === i
                    )

            cats =
                cats &&
                cats.length > 0 &&
                [...cats].map((item) => ({
                    ...item,
                    key: `${item.Categorie}`,
                    label: item.Categorie,
                }))

            let finalCats = []
            if (CatOnderdeel && CatOnderdeel.length > 0) {
                for (const cat of cats) {
                    for (const el of CatOnderdeel) {
                        if (cat.label === el.label) finalCats.push(el)
                    }
                }
            }

            finalCats = finalCats
                .sort((a, b) => a.name.localeCompare(b.Categorie))
                .map((item) => {
                    const nodes = item.nodes.map((node: any) => ({
                        ...node,
                        data: {
                            kennisgebied: {
                                id: ken.id,
                                name: ken.name,
                            },
                            category: { id: item.key, name: item.label },
                            onderdeel: {
                                ...node,
                                name: node.label,
                                id: node.key,
                            },
                        },
                    }))

                    return {
                        ...item,
                        key: `${item.key}`,
                        data: { nodes },
                        nodes,
                    }
                })
            const kenNodes = finalCats.map((el) => el.nodes).flat()
            return {
                key: ken.name,
                label: ken.name,
                ...ken,
                data: { nodes: kenNodes },
                nodes: finalCats,
            }
        })
        .sort((a: any, b: any) => a.name.localeCompare(b.name))

    async function TreeNodeClicked(key: string, label: string, data: any) {
        const keyArray = key?.split('/')
        props.nodeclicked(key)
        dispatch(setBreadCrump({ breadCrump: key }))
        if (keyArray?.length > 2) {
            dispatch(setNode(data))
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0
    }

    function find({ label, searchTerm, key, ...other }: IFindMethodProps) {
        dispatch(reSetSelectNodeState())
        let found = false

        const tref: { nodes: any[] } = other?.data
        if (
            tref?.nodes?.filter((item) =>
                item.Trefwoorden?.toLowerCase().includes(
                    searchTerm.toLowerCase()
                )
            ).length > 0
        ) {
            return true
        } else if (
            other.Trefwoorden?.toLowerCase()?.includes(searchTerm.toLowerCase())
        ) {
            return true
        } else if (label.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true
        }

        //suppose you type in "beton"
        //the first thing you do is retrieve all nodes that contain "beton" in the keywords
        //after that this method is triggered for each node in the treeview when searching
        //when it enters with node "Construction" this function should check if one of our searchresults is in the subnodes of
        //node "Construction". If it is found, then it should return true and this makes sure that "Construction"
        //will be displayed

        return found
    }

    return (
        <Box pt={10} fontSize={15} zIndex={0} width="300px">
            {/* <SearchBar setState={setState} /> */}
            <TreeMenu
                data={kennisgebiedCats}
                onClickItem={({ key, label, data, ...props }) => {
                    TreeNodeClicked(key, label, data)
                }}
                initialActiveKey="f" // the path to the active node
                debounceTime={125}
                hasSearch={true}
                cacheSearch={true}
                matchSearch={({ label, searchTerm, key, nodes, ...props }) => {
                    return find({
                        label,
                        searchTerm,
                        key,
                        ...props,
                    })
                }}
            />
        </Box>
    )
}

export default Treeview
