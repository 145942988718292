import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import Color from './Colors'

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

const breakpoints = createBreakpoints({
    sm: '320px', // mobile
    md: '768px', // tablet
    lg: '1024px', // small desktop
    xl: '1200px', // large desktop
})

export const theme = extendTheme({
    config,
    breakpoints,

    styles: {
        global: {
            body: {
                bg: Color.bgColor,
                color: Color.color,
            },
        },
    },

    fonts: {
        heading: 'Poppins',
        body: 'Poppins',
        text: 'Poppins',
    },
})
