import { call, put } from 'redux-saga/effects'
import { setIdStatus } from './mainDataSlice'

const getIdStatuData = async (): Promise<any> => {
    const res = await fetch('/api/idstatus', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return res.json()
}

const getDefaultIdStatuData = async (): Promise<any> => {
    const res = await fetch('/api/idstatus/default', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return res.json()
}

export function* idStatusSaga(): any {
    try {
        yield put(setIdStatus({ loading: true }))
        const data = yield call(getIdStatuData)
        const defaultIdStatus = yield call(getDefaultIdStatuData)

        yield put(
            setIdStatus({ loading: false, idStatus: data, defaultIdStatus })
        )
    } catch (error) {
        yield put(
            setIdStatus({
                error: { isError: true, value: error },
                loading: false,
            })
        )
    }
}
