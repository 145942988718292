import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './auth/authConfig'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { PublicClientApplication } from '@azure/msal-browser'
import { Provider } from 'react-redux'
import store from './redux/store'

const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <MsalProvider instance={msalInstance}>
                <App />
            </MsalProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(/* $& */
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
