import { Flex, Text } from '@chakra-ui/react'
import IId from '../redux/selectedNode/id.interface'
import TableRow from './TableRow'

export interface IMultipleRowsProps {
    data: IId[]
    title: string
}

function MultipleRows({ data, title }: IMultipleRowsProps) {
    const sortedData = data.sort((a, b) => {
        if (a.title && b.title) {
            return a?.title.localeCompare(b?.title)
        }
        return -1
    })
    if (!data || data.length < 1) return null
    else {
        return (
            <Flex
                justifyContent="space-around"
                bg="blackAlpha.100"
                py={1}
                mb={1}
            >
                <Flex w="28%" px={4} pt={2} fontSize={15} fontWeight="500">
                    <Text>
                        {title
                            ? title[0].toUpperCase() + title.substring(1)
                            : title}
                    </Text>
                </Flex>
                <Flex direction="column" w="70%">
                    {' '}
                    {sortedData?.map((item) => (
                        <TableRow item={item} key={item.id} />
                    ))}
                </Flex>
            </Flex>
        )
    }
}

export default MultipleRows
