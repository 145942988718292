import { call, put, take, takeLatest } from 'redux-saga/effects'

import { setNode, setSelectedNode } from './slice'

export const getSelectedNode = async (data: any): Promise<any> => {
    const res = await fetch('/api/ids/refine', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({ ...data }),
    })
    return res.json()
}

function* getSelectedNodeSaga(action: any): any {
    try {
        const params = {
            kennis: action.payload.kennisgebied.name,
            cat: action.payload.category.name,
            ond: action.payload.onderdeel.name,
        }
        yield put(setSelectedNode({ loading: true }))

        const data = yield call(getSelectedNode, { ...params })
        // const data = yield resData.dat
        yield put(setSelectedNode({ loading: false, node: data }))
        yield put(
            setSelectedNode({
                error: { isError: false, value: null },
                loading: false,
            })
        )
    } catch (error) {
        yield put(
            setSelectedNode({
                error: { isError: true, value: error },
                loading: false,
            })
        )
    }
}

export function* selectedNodeWatcher() {
    yield takeLatest(setNode, getSelectedNodeSaga)
}
