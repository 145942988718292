import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface User {
    isAuth?: boolean
    email?: string | null
    userId?: string | null
    photoURL?: string | null
    userName?: string | null
    accessToken?: string | null
    isAdmin?: boolean
}

const initialState: User = {
    isAuth: false,
    accessToken: null,
}

const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        //vvv
        setUserData(state, action: PayloadAction<User>) {
            const userData = action.payload
            return { ...state, ...userData }
        },
    },
})

export const { setUserData } = userSlice.actions

export default userSlice.reducer
