import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IId from './id.interface'

export type ParamType = {
    id?: string | number
    name: string
}
export interface ISliceState {
    node?: [IId[], number] | null
    data?: any
    breadCrump?: string
    loading?: boolean
    error?: any
    kennisgebied?: ParamType
    category?: ParamType
    onderdeel?: ParamType
}

const initialState: ISliceState = {
    node: null,
    data: null,
    loading: false,
    breadCrump: '',
    error: null,
}

const slice = createSlice({
    name: 'slectedNodeSlice',
    initialState,
    reducers: {
        setNode: (state, action: PayloadAction<ISliceState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setBreadCrump: (state, action: PayloadAction<ISliceState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        setSelectedNode: (state, action: PayloadAction<ISliceState>) => {
            const mainData = action.payload
            return { ...state, ...mainData }
        },
        reSetSelectNodeState(state) {
            return { ...state, ...initialState }
        },
    },
})

export const { setSelectedNode, setNode, setBreadCrump, reSetSelectNodeState } =
    slice.actions

export default slice.reducer
