import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ParamType } from '../selectedNode/slice'

export interface ICreateOrEditId {
    openModal?: boolean
    loading?: boolean
    isUpdate?: boolean
    refreshData?: {
        category?: ParamType
        kennisgebied?: ParamType
        onderdeel?: ParamType
    }

    error?: { status: number; message: string } | null | any
    editFormState?: IEditFormState
}

export interface IDeleteId {
    loading?: boolean
    id?: number
    refreshData?: {
        category?: ParamType
        kennisgebied?: ParamType
        onderdeel?: ParamType
    }
}

export interface IEditFormState {
    id?: { input?: string; error?: string }
    kennisgebied?: { input?: string; error?: string }
    categories?: { input?: string; error?: string }
    onderdeels?: { input?: string; error?: string }
    verwijzing?: { input?: string; error?: string }
    id_status?: { input?: string; error?: string }
    title?: { input?: string; error?: string }
    trefwoorden?: { input?: string; error?: string }
    contactpersoon?: { input?: string; error?: string }
    dbId?: number | null
}

const initialState: ICreateOrEditId = {
    openModal: false,
    loading: false,
    error: null,
    isUpdate: false,
    editFormState: {
        id: { input: '' },
        title: { input: '' },
        id_status: { input: '' },
        kennisgebied: { input: '' },
        categories: { input: '' },
        onderdeels: { input: '' },
        verwijzing: { input: '' },
        trefwoorden: { input: '' },
        contactpersoon: { input: '' },
        dbId: null,
    },
}

const createOrEditSlice = createSlice({
    name: 'createOrEditSlice',
    initialState,
    reducers: {
        //vvv
        opencreateOrEditIdModal(state, action: PayloadAction<ICreateOrEditId>) {
            const openModal = action.payload
            return { ...state, ...openModal }
        },
        setcreateOrEditIdError(state, action: PayloadAction<ICreateOrEditId>) {
            const { error } = action.payload
            return { ...state, error }
        },
        setcreateOrEditId(
            state,
            action: PayloadAction<ICreateOrEditId | IEditFormState>
        ) {
            const payload = action.payload
            return {
                ...state,
                isUpdate: state.isUpdate,
                editFormState: {
                    ...state.editFormState,
                    ...payload,
                },
            }
        },
        submitCreateOrEditId(state, action: PayloadAction<ICreateOrEditId>) {
            const createOrEditId = action.payload
            return {
                ...state,
                ...createOrEditId,
            }
        },
        deleteIdAction(state, action: PayloadAction<IDeleteId>) {
            const createOrEditId = action.payload
            return {
                ...state,
                ...createOrEditId,
            }
        },
        deleteIdActionState(state, action: PayloadAction<IDeleteId>) {
            const createOrEditId = action.payload
            return {
                ...state,
                ...createOrEditId,
            }
        },
        submitEditId(state, action: PayloadAction<ICreateOrEditId>) {
            const payload = action.payload
            return {
                ...state,
                ...payload,
            }
        },
        setcreateOrEditIdState(state, action: PayloadAction<ICreateOrEditId>) {
            const payload = action.payload
            return {
                ...state,
                ...payload,
            }
        },
        reSetcreateOrEditIdState(state, action) {
            return { ...state, ...initialState }
        },
    },
})

export const {
    setcreateOrEditId,
    opencreateOrEditIdModal,
    submitCreateOrEditId,
    setcreateOrEditIdError,
    setcreateOrEditIdState,
    submitEditId,
    reSetcreateOrEditIdState,
    deleteIdAction,
    deleteIdActionState,
} = createOrEditSlice.actions

export default createOrEditSlice.reducer
