import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    Image,
    color,
} from '@chakra-ui/react'
import { FaRegUserCircle, FaThList } from 'react-icons/fa'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../auth/authConfig'
import { IPublicClientApplication } from '@azure/msal-browser'
import Color from './Colors'
import logo from '../assets/logo.png'

export interface INavBarProps {
    isAuth: boolean
}

function Navbar({ isAuth }: INavBarProps) {
    const { instance } = useMsal()
    function handleLogin(instance: IPublicClientApplication) {
        instance.loginRedirect(loginRequest).catch((e) => {
            console.error('auth error', e)
        })
    }

    function handleLogout(instance: IPublicClientApplication) {
        instance.logoutPopup().catch((e) => {
            console.error(e)
        })
    }

    function handleHelp() {
        window.open(
            'https://oosterhoffgroup.sharepoint.com/sites/Wiki-oosterhoff/SitePages/BRiCks.aspx',
            '_blank'
        )
    }

    return (
        <Flex direction="column" position="fixed" zIndex={100} width="1000px">
            <Flex
                height={12}
                bg={Color.mintGreen}
                w="100%"
                alignItems="center"
                justifyContent="end"
                px="15%"
                p={7}
            >
                <Image src={logo} h={8} />
            </Flex>
            <Flex
                w="100%"
                p={4}
                height={12}
                justifyContent="space-between"
                alignItems="center"
                bg="gray.100"
            >
                <Flex flexDirection="row" w="100%" p={5}>
                    <Flex
                        flexDirection="column"
                        width={'7%'}
                        justifyContent="center"
                    >
                        <FaThList size="38" color={Color.mintGreen} />
                    </Flex>
                    <Flex flexDirection="column" width={'93%'}>
                        <Text fontSize={30} color={Color.mintGreen}>
                            BRiCks 2.0
                        </Text>
                    </Flex>
                </Flex>
                {isAuth ? (
                    <>
                        <Button
                            // rightIcon={<FaRegUserCircle size={24} />}
                            colorScheme="gray"
                            variant="ghost"
                            py={6}
                            onClick={() => handleHelp()}
                        >
                            Help
                        </Button>
                        <Text>|</Text>
                        <Button
                            // rightIcon={<FaRegUserCircle size={24} />}
                            colorScheme="gray"
                            variant="ghost"
                            py={6}
                            onClick={() => handleLogout(instance)}
                        >
                            Uitloggen
                        </Button>
                    </>
                ) : (
                    <Button
                        // rightIcon={<FaRegUserCircle size={24} />}
                        colorScheme="gray"
                        variant="outline"
                        py={6}
                        onClick={() => handleLogin(instance)}
                    >
                        Inloggen
                    </Button>
                )}
            </Flex>
        </Flex>
    )
}

export default Navbar
