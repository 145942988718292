export const msalConfig = {
    auth: {
        clientId: '9c2ecb50-a779-4349-84d2-833c801283df',
        authority:
            'https://login.microsoftonline.com/356ac9b5-f5ee-4289-8d03-d46dd1f5f2c7',
        redirectUri: window.location.href,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
}
