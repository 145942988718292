import { combineReducers, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import mainState from './mainData/mainDataSlice'
import userState from './user/userSlice'
import selectedNodeState from './selectedNode/slice'
import rootSaga from './rootSaga'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import createOrEditId from './createOrEditId/createOrEditId'

const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({
    mainState,
    userState,
    selectedNodeState,
    createOrEditId,
})

let middlewares: any[] = []
if (process.env.NODE_ENV === 'development') {
    const { logger } = require(`redux-logger`)
    middlewares = [...middlewares, sagaMiddleware, logger]
} else {
    middlewares = [...middlewares, sagaMiddleware]
}

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat([...middlewares]),
    devTools: true,
})
sagaMiddleware.run(rootSaga)
export type RootState = ReturnType<typeof rootReducer>
// Use throughout your app instead of plain `useDispatch` and `useSelector`
// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
