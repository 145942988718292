import { call, put } from 'redux-saga/effects'
import { setCategories } from './mainDataSlice'

const getCategoriesData = async (): Promise<any> => {
    const res = await fetch('/api/categories', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
    return res.json()
}

export function* categoriesDataSaga(): any {
    try {
        yield put(setCategories({ loading: true }))
        const data = yield call(getCategoriesData)

        yield put(setCategories({ loading: false, categories: data }))
    } catch (error) {
        yield put(
            setCategories({
                error: { isError: true, value: error },
                loading: false,
            })
        )
    }
}
