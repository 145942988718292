import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    AlertDialogCloseButton,
    Tag,
    TagLabel,
    Flex,
} from '@chakra-ui/react'

import {
    IEditFormState,
    submitCreateOrEditId,
} from '../../redux/createOrEditId/createOrEditId'
import { ParamType } from '../../redux/selectedNode/slice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import EditForm from './EditForm'

export interface IEditModalProps {
    isOpen?: boolean
    onOpen?: () => void
    onClose: () => void
    cancelRef?: any
}

function EditModal({ isOpen, onClose, cancelRef }: IEditModalProps) {
    const { editFormState, error, loading, isUpdate } = useAppSelector(
        (state) => state.createOrEditId
    )
    const { category, kennisgebied, onderdeel } = useAppSelector(
        (state) => state.selectedNodeState
    )

    const dispatch = useAppDispatch()
    const setState = (
        state: IEditFormState,
        category?: ParamType,
        kennisgebied?: ParamType,
        onderdeel?: ParamType
    ) =>
        dispatch(
            submitCreateOrEditId({
                isUpdate,
                refreshData: { category, kennisgebied, onderdeel },
                ...state,
            })
        )
    const onSubmit = async () => {
        setState(editFormState!, category, kennisgebied, onderdeel)
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen as boolean}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                size="2xl"
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Nieuw onderdeel
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />

                        <AlertDialogBody>
                            {error && (
                                <Flex justifyContent="center" py={2}>
                                    <Tag
                                        size="md"
                                        variant="subtle"
                                        colorScheme="red"
                                    >
                                        <TagLabel>
                                            Error: {error.message}
                                        </TagLabel>
                                    </Tag>
                                </Flex>
                            )}
                            <EditForm
                                state={editFormState}
                                setState={setState}
                            />
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button
                                colorScheme="teal"
                                onClick={onSubmit}
                                isLoading={loading}
                                loadingText="Bezig met opslaan"
                                spinnerPlacement="end"
                            >
                                Opslaan
                            </Button>
                            <Button ref={cancelRef} onClick={onClose} ml={3}>
                                Annuleren
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default EditModal
