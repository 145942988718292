import React from 'react'
import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from '@chakra-ui/react'

export interface IAlertBannerProps {
    status: 'info' | 'warning' | 'success' | 'error' | undefined
    variant: string
    title: string
    description: string
}
function AlertBanner({ status, description }: IAlertBannerProps) {
    return (
        <Alert status={status} variant="subtle">
            {description}
        </Alert>
    )
}

export default AlertBanner
