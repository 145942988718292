import { all } from 'redux-saga/effects'
import {
    createWatcher,
    editWatcher,
    deleteWatcher,
} from './createOrEditId/createOrEditIdSaga'
import { categoriesDataSaga } from './mainData/categorieSaga'
import { idStatusSaga } from './mainData/idStatus'
import { kennisgebiedSaga } from './mainData/kennisgebiedSaga'
import { mainDataWatcher } from './mainData/mainDataSaga'
import { onderdeelSaga } from './mainData/onderdeelSaga'
import { selectedNodeWatcher } from './selectedNode/saga'
import { adminDataSaga } from './mainData/adminSaga'

export default function* rootSaga() {
    yield all([
        mainDataWatcher(),
        kennisgebiedSaga(),
        categoriesDataSaga(),
        onderdeelSaga(),
        idStatusSaga(),
        selectedNodeWatcher(),
        createWatcher(),
        deleteWatcher(),
        editWatcher(),
        adminDataSaga(),
    ])
}
