import React from 'react'
import { Box, Flex, Text, Button, Center, color } from '@chakra-ui/react'
import Color from '../Layout/Colors'
import ReactPlayer from 'react-player'

function Login() {
    function handleContact() {
        window.open('mailto://bricks@quake-innovation.eu', '_blank')
    }

    return (
        <Flex
            boxShadow="2xl"
            w="1000px"
            py={1}
            mt="84px"
            marginTop={150}
            justifyContent="center"
        >
            <Flex bg="white" p={4}>
                <Flex direction="column" alignItems="center">
                    <Text fontSize="sm" textAlign="center">
                        BRiCks is er voor ieder bedrijf. BRiCks leidt gebruikers
                        naar de juiste gevalideerde (reken-)software. <br />
                        Met BRiCks worden spreadsheets en tools via de browser
                        ontsloten én voorzien van kwaliteitslabels. <br />
                        <br />
                        Klik rechtsboven op inloggen om direct te starten.
                        <br />
                        Vragen? Neem contact op met Quake.
                    </Text>
                    <Flex justifyContent={'center'} paddingTop={4} w="100%">
                        <Button
                            bg={Color.mintGreen}
                            color="white"
                            variant="outline"
                            py={4}
                            onClick={() => handleContact()}
                        >
                            Contact
                        </Button>
                    </Flex>
                    <Flex justifyContent={'center'} paddingTop={4} w="80%">
                        <iframe
                            src="https://player.vimeo.com/video/737570213?h=0ee2bd5bd8"
                            width="640"
                            height="360"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Login
